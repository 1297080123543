import dynamic from "next/dynamic";
import { loadStandardContent } from "utils/api";
import { PageHead } from "components/metadata";
import Layout from "components/layout";

import { ComponentMap } from "components";
const Newsletter = dynamic(() => import("components/newsletter"));

export default function Page(props) {
  const {
    fields: { title, type, url, content },
  } = props.entry;

  return (
    <>
      <PageHead metadata={props.entry.fields.metadata} />
      <Layout siteData={props.siteData}>
        {Array.isArray(content) &&
          content.map(({ fields, sys }, index) => {
            if (ComponentMap[sys.contentType.sys.id]) {
              const Cmp = ComponentMap[sys.contentType.sys.id];
              return (
                <Cmp
                  key={`${index}-${sys.contentType.sys.id}`}
                  fields={fields}
                  first={index === 0}
                  sys={sys}
                  includes={props.includes}
                />
              );
            }
            console.log(`NOT FOUND: ${sys.contentType.sys.id}`);
            return null;
          })}
        <Newsletter />
      </Layout>
    </>
  );
}

export async function getStaticProps({ params, preview = false }) {
  const contentful = require("contentful");
  const client = contentful.createClient({
    space: process.env.NEXT_CONTENTFUL_SPACE || "",
    accessToken: process.env.NEXT_CONTENTFUL_KEY || "",
  });

  const entry = await client.getEntry("5QAuKRxPhoABYixnFUIJqv", { include: 3 });
  const siteData = await loadStandardContent();

  return {
    props: {
      entry: entry,
      includes: entry.includes || {},
      siteData,
    },
  };
}
